<template>
  <div>
    <b-container>
      <h1 class="mt-3">Stage 1</h1>
      <h3>Trimming and Quality Control of Short Read FASTQ Files</h3>
      <p class="text-left">The Fastp tool is used for adapter trimming and quality filtering of the raw read fastq files. Once the Fastp analysis is done, reports are generated to summary the read quality before and after trimming for the samples. Users can click and visualise the reports in the Track Existing Jobs page.</p>
      <p class="text-left">Parameter settings: 
        <ul>
          <li>Quality Filter: Quality value that a base is qualified. Default 20 means phred quality >=Q20 is qualified.</li>
          <li>Length Filter: Reads shorter than this length will be discarded, default is 30.</li>
        </ul>
      </p>

      <b-row><b-col>
        <p class="m-0 font-weight-bold">Select the folder which contains the relevant dataset:</p>
        <b-alert show variant="danger" v-if=errorMsg>{{ errorMsg }}
          <div v-if=(errorShowRefresh)>Please refresh this page and try again, or contact an administrator if you continue to see this error.</div>
        </b-alert>
      </b-col></b-row>
    
      <b-form @submit.prevent="submitJob">
        <folder-browser :folders="folders" :selectedFolder="selectedFolder"
          @select-folder="folderSelected" >
        </folder-browser>
        <b-row class="input-row">
            <b-input-group>
              <b-input-group-prepend class="input-label">
                  <b-input-group-text class="bg-white w-100">Quality Filter:</b-input-group-text>
                </b-input-group-prepend>
              <div class="input-box" v-b-tooltip.hover.html :title="qualityTip">
                <b-form-input id="qualityInput" v-model="quality" type="number" min="0" class="bg-secondary"></b-form-input>
              </div>
            </b-input-group>          
          </b-row>

          <b-row class="input-row">
            <b-input-group>
              <b-input-group-prepend class="input-label">
                <b-input-group-text class="bg-white w-100">Length Filter:</b-input-group-text>
              </b-input-group-prepend>
              <div class="input-box" v-b-tooltip.hover.html :title="lengthTip">
                <b-form-input id="lengthInput" v-model="length" type="number" min="0" class="bg-secondary"></b-form-input>
              </div>
            </b-input-group>
          </b-row>

          <b-form-checkbox
            id="NameCheck"
            v-model="nameCheck"
            name="NameCheck"
          >
            Tick if you want to use a custom name for the results folder.
          </b-form-checkbox>

          <b-row v-if="nameCheck" class="input-row">
            <b-input-group>
              <b-input-group-prepend class="input-label">
                <b-input-group-text class="bg-white w-100">Custom folder name:</b-input-group-text>
              </b-input-group-prepend>
              <div class="input-box">
                <b-form-input id="nameInput" v-model="name" type="text" class="bg-secondary"></b-form-input>
              </div>
            </b-input-group>
          </b-row>
            
        <b-button type="submit" variant="primary" :disabled="selectedFolder.files.length === 0 || errorMsg != null">Submit</b-button>
      </b-form>

    </b-container>
  </div>
</template>

<script>
import FolderBrowser from '../components/FolderBrowser.vue'
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: "trimming",
  components: {
    "folder-browser": FolderBrowser
  },
  data() {
    return {
      // Error message that we want to display (if any)
      errorMsg: null,
      // Should the UI show a 'please refresh' msg when showing the error
      errorShowRefresh: true,
      data: null,
      quality: 20,
      length: 30,
      qualityTip: "Quality value that a base is qualified. Default 20 means phred quality >=Q20 is qualified.",
      lengthTip: "Reads shorter than this length will be discarded, default is 30.",
      folders: null,
      selectedFolder: {
        name: null,
        files: []
      },
      nameCheck: false,
      name: ""
    }
  },

  computed: {
    ...mapGetters({token: "token"})
  },

  mounted() {
    axios.get("/folders?stage=sr1", 
      {
        params: { token: this.token },
        headers: {"Content-Type": "application/json"}
      }).then(
        function(response) {
          this.folders = response.data;
          this.onLoad();
        }.bind(this)
      )
      .catch(error => {
        console.log(error)
        this.errorMsg = "Unable to retrieve a list of folders.";
      });
  },

  methods: {
    onLoad: function() {
      if(this.$route.params.resubmitData != null && this.$route.params.resubmitData != undefined)
      {
        this.folderID = this.$route.params.resubmitData.dataFolder;
        if(this.$route.params.resubmitData != null && this.$route.params.resubmitData != undefined)
        {
          this.quality = this.$route.params.resubmitData.quality;
          this.length = this.$route.params.resubmitData.length;
          if(this.folderID !== undefined && this.folderID !== null)
          {
            this.folders.forEach(folder => {
              if((folder.id.localeCompare(this.folderID) == 0))
              {
                this.folderSelected(folder);
              }
            });
          }
        }
      }
    },

    folderSelected: function(folder) {
      this.selectedFolder = folder;
    },

    submitJob: function() {
      var custName = null;
      if(this.nameCheck) {
        custName = this.name;
      }

      axios.get("/shortread/trimming", {
        params: {
          token: this.token,
          dataFolder: this.selectedFolder.id,
          quality: this.quality,
          length: this.length,
          name: custName
        },
        headers: {"Content-Type": "application/json"}
        }).then(
          function(response) {
            this.data = response.data;

            this.$router.push({ name: 'tracking', params: { folderID: response.data.folderID }})

          }.bind(this)
        )
        .catch(error => {
          console.log(error)
          this.errorMsg = "ERROR";
        });
    }
  }
}
</script>

<style scoped>
.error {
  color: #ff0000;
}
</style>